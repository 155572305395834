<template>

<app-content :loading="is.loading">

	<app-content-head title="Settings" subtitle="Manage your library settings." :readonly="$authorised('org/library/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('org/library/access', 'read')">

			<app-input-text :toggle="model.settings.library.sync.enabled" v-on:toggle="model.settings.library.sync.enabled = !model.settings.library.sync.enabled" label="BGG Username" placeholder="Enter username..." v-model="model.settings.library.sync.username" :validation="$v.model.settings.library.sync.username" />
			<app-input-text :toggle="model.settings.library.external.enabled" v-on:toggle="model.settings.library.external.enabled = !model.settings.library.external.enabled" label="External Website" placeholder="Enter URL..." v-model="model.settings.library.external.url" :validation="$v.model.settings.library.external.url" notes="If you have an external website for browsing your collection you can link there instead of using the internal tool." />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			settings: true,
			forceModelLoad: true,
			model: {
				settings: {
					library: {
						sync: {
							enabled: false,
							username: ''
						},
						external: {
							enabled: false,
							url: ''
						}
					}
				}
			}
		}

	},

	validations: {
		model: {
			settings: {
				library: {
					sync: {},
					external: {}
				}
			}
		}
	},

	computed: {

		defaultSettings: function() {

			return []

		}

	}

}

</script>

<style scoped>

</style>